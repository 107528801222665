(function($) {

    function Permissions()
    {
        var _self = this;
        this.baseClass = 'proxy-requires-permission';
        this.btnSelector = '.'+this.baseClass;
        this.popupSelector = '.proxy-permission-popup';
        this.overlaySelector = '.proxy-permission-overlay';

        this.permissionOptions = {};
        /**
         * @todo
         *  Be sure to handle the dependency on megaAjaxPagination
         */
        this.init = function()
        {
            if(MegaProxy.users.isLoggedIn) {
                this.logged();
            }
        };

        this.jqInit = function($el){
            if(!$el.hasClass(_self.baseClass)){
                $el.addClass(_self.baseClass);
            } 

            //re-init with new selector
            this.init();
        };


        this.notLogged = function()
        {
            //this.container.hide();
            $(this.overlaySelector).css({'display':'none'});
        };



        this.initPermissions = function(options){
            _self.setButtonEvents();
            _self.setPopupEvents();
            _self.enableOverlay();
        };

        /**
         * @todo
         *   Using the id comments is rather confusing
         */
        this.logged = function()
        {
            this.initPermissions();
        };

        this.goToPermissionPage = function(permission){
            var base_url = $('.proxy-login-link').data('baseurl');

            window.location = base_url + '/verification/permissions?type='+permission;
        };

        this.setButtonEvents = function(){
            $(_self.btnSelector).off('click.proxy-permission').on('click.proxy-permission', function(e){
                var permission = $(this).data('proxy-permission');

                if(!_self.userHasPermission(permission)){
                    e.preventDefault();
                    _self.goToPermissionPage(permission);
                }
            });
        };

        this.enableOverlay = function(){
                var $overlay = $(this.overlaySelector);
                var permission = $overlay.data('proxy-permission');
                if(!_self.userHasPermission(permission)){
                    $overlay.css({'display':'block'});
                }
        };

        this.setPopupEvents = function(){
            //there should only ever be one of these.

            var $popup = $(this.popupSelector);
            if($popup.length > 0){
                var permission = $popup.data('proxy-permission');

                if(!_self.userHasPermission(permission)){
                    _self.goToPermissionPage(permission);
                }
            }
        };

        this.userHasPermission = function(permission){
            if(permission !== undefined && MegaProxy.users.user.permissions !== null && MegaProxy.users.user.permissions.indexOf(permission) !== -1){
                return true;
            }
            return false;
        };

    }

    MegaProxy.extend('permissions', new Permissions());

})(jQuery);
