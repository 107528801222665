(function ($, window, undefined) {
    
    var MegaAjaxPagination = MegaAjaxPagination || {};
    
    $.fn.megaAjaxPagination = function(options) {
        var settings = $.extend({
            paginationClass     : 'pagination-ajax',
            paginationTemplate  : 'paginationTemplate',
            resultClass         : 'result-ajax',
            resultTemplate      : 'resultTemplate',
            callback            : '/mega/ajax/pagination'
        }, options );
        
        MegaAjaxPagination.Bind(this, settings);
    };
    
    MegaAjaxPagination.cache = [];
    MegaAjaxPagination.clicked = [];
    
    MegaAjaxPagination.Bind = function($container, settings) {
        var $ajax_pagination = $container.find('.' + settings.paginationClass);
        var $ajax_result = $container.find('.' + settings.resultClass);
        $ajax_pagination.find('a').click(function(e) {
            var page = $(this).data('page');
            if(MegaAjaxPagination.clicked[page] === undefined) {
                $.ajax({
                    type: 'GET',
                    url: settings.callback,
                    cache: true,
                    dataType: 'json',
                    data: { 
                        object_id   : $container.data('objectid'),
                        object_type : $container.data('objecttype'),
                        limit       : $container.data('limit'),
                        page        : page
                    },
                    success: function(response) {
                        MegaAjaxPagination.cache[page] = response.data;
                        MegaAjaxPagination.UpdateHtml($container, settings, $ajax_pagination, $ajax_result, response.data);
                    },
                    xhrFields: { withCredentials: true }
                });
            }
            else if(MegaAjaxPagination.cache[page] !== undefined) {
                MegaAjaxPagination.UpdateHtml($container, settings, $ajax_pagination, $ajax_result, MegaAjaxPagination.cache[page]);
            }
            MegaAjaxPagination.clicked[page] = true;
            e.preventDefault();
        });
    };
    
    MegaAjaxPagination.UpdateHtml = function($container, settings, $ajax_pagination, $ajax_result, data) {
        $ajax_pagination.empty();
        $('#' + settings.paginationTemplate).tmpl(data.pager).appendTo($ajax_pagination);

        $ajax_result.empty();
        $('#' + settings.resultTemplate).tmpl(data.results).appendTo($ajax_result);

        MegaAjaxPagination.Bind($container, settings);
    };
    
    $.extend(jQuery.tmpl.tag, {
        "for": {
            _default: {$2: "var i=1;i<=1;i++"},
            open: 'for ($2){',
            close: '};'
        }
    });
}(jQuery, window));