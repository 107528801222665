(function($) {

    $(document).ready(function(){
        $.ajaxPrefilter( function( options, originalOptions, jqXHR ) {
          options.crossDomain ={
            crossDomain: true
          };
          options.xhrFields = {
            withCredentials: true
          };
        });
    });
    

    MegaProxy = (function(){
        var Proxy = function(){
            var _self = this;
            this.settings = null;
            this.loginProcessed = false;
            this.baseUrl = null;
            this.reInitCallbacks = [];
            this.initCallbacks = [];
            this.loginCallbacks = [];
            this.logoutCallbacks = [];
            this.watchers = [];
        
            this.init = function(options){
                var defaults = {
                    allowKids:false,
                    baseUrl:'/proxy'
                };
                this.settings = $.extend(defaults, this.settings, options);
                this.baseUrl = this.settings.baseUrl; 
                //TODO - These need to fire in a certain order. Need to ensure this happens
                this.executeCallbacks('init');
                //modules are self initiating, if user was found all login calls were processed. 
                this.loginProcessed = true;

                //Now that everything has auto initialized quickly based on cookie. Make sure we truly have a user and then log them in/out accordingly.
                this.reinit();
            };

            this.getCookie = function(name)
            {
                var re = new RegExp(name + "=([^;]+)");
                var value = re.exec(document.cookie);
                return (value !== null) ? unescape(value[1]) : null;
            };

            this.reinit = function()
            {
                if(this.isModuleEnabled('events')){
                    this.events.triggerEvent('reinit');
                }

                //could possibly just push this as a callback
                if(this.isModuleEnabled('users')){
                    this.users.refreshUserInfo();
                }
            };

            this.logout = function(){
                this.executeCallbacks('logout');
                this.loginProcessed = false;
            };

            this.login = function(){
                this.executeCallbacks('login', _self.users.user);
                this.loginProcessed = true;
            };

            this.extend = function(module, obj){
                this[module] = obj;
                this.createCallbacks(this[module]);
            };

            this.createCallbacks = function(module){
                if(typeof module.init === 'function'){
                    this.registerCallback('init', function(){
                        module.init.call(module);
                    });
                }

                if(typeof module.logged === 'function'){
                    this.registerCallback('login', function(){
                        module.logged.call(module);
                    });
                }

                if(typeof module.notLogged === 'function'){
                    this.registerCallback('logout', function(){
                        module.notLogged.call(module);
                    });
                }
            };

            this.registerCallback = function(type, callback){
                switch(type){
                    case 'init':
                        this.initCallbacks.push(callback);
                        break;
                    case 'login':
                        this.loginCallbacks.push(callback);
                        break;
                    case 'logout':
                        this.logoutCallbacks.push(callback);
                        break;
                    case 'reinit':
                        this.reInitCallbacks.push(callback);
                        break;
                }
            };

            this.executeCallbacks = function(type, context){
                var callbacks = []; 

                switch(type){
                    case 'init':
                        callbacks = this.initCallbacks;
                        break;
                    case 'login':
                        callbacks = this.loginCallbacks;
                        break;
                    case 'logout':
                        callbacks = this.logoutCallbacks;
                        break;
                    case 'reinit':
                        callbacks = this.reInitCallbacks;
                        break;
                }

                callbacks.forEach(function(callback){ 
                    callback.call(this, context); 
                });
            };

            this.isModuleEnabled = function(module){
                return (typeof this[module] !== "undefined");
            };
        };

        return new Proxy();

    })();
})(jQuery);
