    /**
     * @todo store all haves wants in one storage bin instead of
     *  one bin per product
     *
     * @todo Remove all storage checks, we should force storage
     */
(function($) {

    function HavesWants(storage) 
    {
        var _self = this;
        // TODO: implement this below, 
        //   so that the button class is configurable 
        this.baseClass = 'action-button';
        this.btnSelector = '.'+this.baseClass;
        this.store = new sessionStore();


        /**
         * Initialize the widget
         */
        this.init = function() 
        {
            if(MegaProxy.users.isLoggedIn) {
                this.logged();
            } else {
               this.notLogged(); 
            } 
        };

        this.jqInit = function($el){
            if(!$el.hasClass(_self.baseClass)){
                $el.addClass(_self.baseClass);
            } 

            //re-init with new selector
            this.init();
        };

        /**
         * Update the data with the users data
         */
        this.update = function() 
        {
            var store = this.store;
            var params = jQuery(this.btnSelector).first().data('params');

            // If no params then no button exists yet
            if(params) {

                var id = params.object_id;
                var storeId = this.storeId(params.object_type);

                var havewants = this.store.get(storeId);
                if(!havewants) {
                    havewants = {};
                }

                var havesBtn = $(this.btnSelector+'[data-output="haves"]');
                var wantsBtn = $(this.btnSelector+'[data-output="wants"]');

                if(!havewants.hasOwnProperty(id) || !havewants[id].hasOwnProperty('action_id')) {

                    jQuery.ajax({
                        url: MegaProxy.baseUrl + '/haves-wants',
                        data: params,
                        type: "GET",
                        success: function(response) 
                        {
    
                            if(response) {

                                var params = {};

                                var havesCount = parseInt(jQuery('#haves').html(), 10);
                                var wantsCount = parseInt(jQuery('#wants').html(), 10);

                                // Set the action_id if the user already has or wants this product
                                if(response.hasOwnProperty('data') && response.data.haves) {

                                    params = havesBtn.data('params');
                                    params.action_id = response.data.haves;
                                    havesBtn.data('params', params).attr('disabled', 'disabled').addClass('active');

                                    id = params.object_id;
                                    jQuery('#haves').html(havesCount);
                                    havewants[id] = { "haves": havesCount, 'action_id': params.action_id };

                                } else if(response.hasOwnProperty('data') && response.data.wants) {

                                    params = havesBtn.data('params');
                                    params.action_id = response.data.wants;
                                    wantsBtn.data('params', params).attr('disabled', 'disabled').addClass('active');

                                    id = params.object_id;
                                    jQuery('#wants').html(wantsCount);
                                    havewants[id] = { "wants": wantsCount, 'action_id': params.action_id };

                                }

                                //Store the new haves/wants count
                                store.set(storeId, JSON.stringify(havewants));
                            }
                        },
                        xhrFields: { withCredentials: true }
                    });

                } 

                if(havewants.hasOwnProperty(id)) {
                    var data = havewants[id];
                    if(data.hasOwnProperty('action_id')){
                        params.action_id = data.action_id;
                    }
                    if(data.hasOwnProperty('haves')) {
                        jQuery('#haves').html(data.haves);
                        havesBtn.data('params', params).attr('disabled', 'disabled').addClass('active');
                    } else if(data.hasOwnProperty('wants')) {
                        jQuery('#wants').html(data.wants);
                        wantsBtn.data('params', params).attr('disabled', 'disabled').addClass('active');
                    } 
                }
            }
        };

        /**
         * @todo
         * This works for megabloks.com only
         *  we'll need to come up with a generic solution that
         *  works with mobile devices 
         */
        this.notLogged = function()
        {
            jQuery('body').off('click.proxy-havewants').on('click.proxy-havewants', this.btnSelector, function(e) {
                e.preventDefault();
                jQuery('#tooltip-msg').fadeIn();
            }).on('mouseleave', function() {
                setTimeout(function() {
                    jQuery('#tooltip-msg').fadeOut();
                }, 4000);        
            });
        };

        /**
         * Configure the widget
         */
        this.logged = function() 
        {
            this.update();
            var parent = this;
            var btnSelector = this.btnSelector;
            var store = this.store;

            jQuery('body').off('click.proxy-havewants').on('click.proxy-havewants', this.btnSelector, function(e) {
                e.preventDefault();
                var $that = jQuery(this);
                // Decrement displayed value
                jQuery(btnSelector).each(function() {
                    if(jQuery(this).attr('disabled')) {
                        var output = jQuery('#'+jQuery(this).data('output'));
                        if (parseInt(output.html(), 10) > 0) {
                            output.html(parseInt(output.html(), 10) - 1);
                        }
                    }
                });

                var endpoint; //line below leaks global var (endpoint)
                var outputId = endpoint = $that.data('output');
                var params = $that.data('params');

                var storeId = parent.storeId(params.object_type);

                var havewants = store.get(storeId);
                if(!havewants) {
                    havewants = {};
                }

                // Clicking an inactive action button
                if(!$that.attr('disabled')) {
                    // Reset all action buttons
                    jQuery(btnSelector).removeAttr('disabled').removeClass('active');

                    // Enable clicked action button
                    $that.attr('disabled','disabled').addClass('active');

                    var id = params.object_id;
                    var output = jQuery('#'+outputId);
                    var count = parseInt(output.html(), 10) + 1;

                    output.html(count);

                    havewants[id] = {}; 
                    havewants[id][outputId] = count;

                    store.set(storeId, JSON.stringify(havewants));

                    // Add user action
                    jQuery.ajax({
                        url: MegaProxy.baseUrl + '/' + endpoint,
                        data: params,
                        type: "POST",
                        success: function(response) {
                            if (response.code == 201) {
                                params.action_id = response.data.id;
                                $that.data('params', params);
                                havewants[id].action_id = response.data.id;
                                store.set(storeId, JSON.stringify(havewants));
                            }
                        },
                        error: function(error) {
                        },
                        xhrFields: { withCredentials: true }
                    });

                // Clicking an active action button
                } else {

                    // Reset all action buttons
                    jQuery(btnSelector).removeAttr('disabled').removeClass('active');

                    var id = params.object_id;
                    delete havewants[id];

                    store.set(storeId, JSON.stringify(havewants));

                    // Delete user action
                    jQuery.ajax({
                        url: MegaProxy.baseUrl + '/' + endpoint + '/' + params.action_id,
                        type: "DELETE",
                        success: function(response) {
                            if (response.code == 204) {
                               delete params.action_id;
                               $that.data('params', params);
                            }
                        },
                        error: function(error) {
                        },
                        xhrFields: { withCredentials: true }
                    });
                }
            });
        };

        this.updateCounts = function(options){

            var $buttons = $(this.btnSelector),
                $havesBtn = $buttons.filter('[data-output="haves"]'),
                $wantsBtn = $buttons.filter('[data-output="wants"]'),
                params = $buttons.first().data('params');

            if($havesBtn.length > 0){
               $.ajax({
                   url: MegaProxy.baseUrl + '/haves',
                   data: params,
                   type: "GET", 
                   success:function(response){
                       if(response.hasOwnProperty('data')){
                            $havesBtn.find('.item-total').html(response.data.count);
                       }
                   },
                   xhrFields: { withCredentials: true }
               }); 
            }
            
            if($wantsBtn.length > 0){
                $.ajax({
                    url: MegaProxy.baseUrl + '/wants',
                    data: params,
                    type: "GET", 
                    success:function(response){
                        if(response.hasOwnProperty('data')){
                            $wantsBtn.find('.item-total').html(response.data.count);
                        }
                    },
                    xhrFields: { withCredentials: true }
                });
            }
        };

        this.storeId = function(object_type) 
        {
           return "haveswants_" + object_type; 
        };
    }

    MegaProxy.extend('haves_wants', new HavesWants());

})(jQuery);