/**
 * @todo store all haves wants in one storage bin instead of
 *  one bin per product
 *
 * @todo Remove all storage checks, we should force storage
 */
(function($) {

    function Comments() 
    {
        var _self = this;
        this.listContainer = $('#tab1');
        this.commentBtn = $('#comment-button');
        this.postOptions = {};

        /**
         * @todo
         *  Be sure to handle the dependency on megaAjaxPagination
         */
        this.init = function()
        {
            this.commentPagination(this.listContainer);
            if(MegaProxy.users.isLoggedIn) {
                this.logged();
            }
        };

        this.notLogged = function()
        {

        };

        /**
         * @todo
         *   Using the id comments is rather confusing
         */
        this.logged = function()
        {
            var container =  $('.comments-content');
            container.find('.logged-in').show();
            container.find('.logged-out').hide();

            //This logic is inconsistent across all the sites. å
            this.userCommentContainer(container.find('div[data-objectid]'));

            this.postCommentEvent(this.commentBtn);
        };

        this.userCommentContainer = function($container){
            var page = parseInt($('.pager-active a').html()) || 0;
            if($container.find('.result-ajax').length > 0 && page <= 1){
                var id = $container.data("objectid");
                //var id = $container.find("input[name='object_id']").val();
                if (!sessionStorage.getItem('comment-body-' + id)) {
                    // Display the comment div
                    $('#comment-form-container').show();
                } else if(MegaProxy.users.isLoggedIn) {

                    var values = {};
                    var date = sessionStorage.getItem('comment-created-' + id);
                    values.body = sessionStorage.getItem('comment-body-' + id);
                    values.user = { 
                        username: MegaProxy.users.user.username,
                        avatar: MegaProxy.users.user.avatar
                    };
                    $('.result-ajax').prepend($('#newTemplate').tmpl(values));
                }
            }
        };

        this.refreshComments = function($container, options){
            var defaults = {
                    template:"#resultTemplate",
                    pagerTemplate:"#paginationTemplate",
                    result:".result-ajax",
                    paginationWrapper:".pagination-ajax",
                    'count':'.comments-count'
                };

            if(typeof options === 'undefined'){
                options = {};
            }
            
           var settings = $.extend(defaults, options);

           if($container.length > 0){
                var page = parseInt($container.data('page')) || 1; 
                var params = {
                    object_id   : $container.data('objectid'),
                    object_type : $container.data('objecttype'),
                    limit       : $container.data('limit'), 
                    page        : page
                };

                $.ajax({
                    url: MegaProxy.baseUrl + '/comments',
                    data: params,
                    type: "GET", 
                    success:function(response){
                        if(response.hasOwnProperty('data') && response.data.results !== null){
                            $(settings.count).html(response.data.pager.count);
                            var $result_container = $(settings.result),
                            $ajax_container = $(settings.paginationWrapper);
                            $result_container.empty();
                            $(settings.template).tmpl(response.data.results).appendTo($result_container);

                            if($ajax_container.length > 0 && response.data.hasOwnProperty('results')){
                                if(response.data.pager.pages > 1){
                                    $ajax_container.empty();
                                    $(settings.pagerTemplate).tmpl(response.data.pager).appendTo($ajax_container);
                                    $container.comments().pagination();
                                }
                            }
                            _self.userCommentContainer($container);
                        }
                    },
                    xhrFields: { withCredentials: true }
                });
           }
        };

        this.pushBeforePostCallbacks = function(callback){
            var beforePostCallbacks = this.beforePostCallbacks || [];
            beforePostCallbacks.push(callback);
            this.beforePostCallbacks = beforePostCallbacks;
        };

        this.pushAfterPostCallbacks = function(callback){
            var afterPostCallbacks = this.afterPostCallbacks || [];
            afterPostCallbacks.push(callback);
            this.afterPostCallbacks = afterPostCallbacks;
        };

        this.postCommentEvent = function($button, settings){
            var defaults = {
                    container:"#tab2",
                    body:'textarea[name=body]',
                    form:'#comment-form',
                    results:'.result-ajax',
                    template:'#newTemplate',
                    commentsWrapper:'#tab1',
                };

            if(typeof settings === 'undefined'){
                settings = {};
            }
            
            _self.postOptions = $.extend(defaults, _self.postOptions, settings);
        
            //don't push callbacks more than once. Use only what's passed in
            if(settings.hasOwnProperty("beforePost")){
                this.pushBeforePostCallbacks(settings.beforePost);
            }

            if(settings.hasOwnProperty("afterPost")){
                this.pushAfterPostCallbacks(settings.afterPost);
            }

            this.userCommentContainer($(_self.postOptions.commentsWrapper));
            //unbind any previous click events
            $button.off('click')
            .on('click', function(e)
            {
                e.preventDefault();
                var $container = $(_self.postOptions.container), 
                form  = $(_self.postOptions.form),
                params = form.serialize(), 
                $bodyElem = form.find(_self.postOptions.body),
                body = $bodyElem.val(),
                date = new Date(),
                id = form.find('input[name="object_id"]').val();

                var valid = true;

                //trigger whatever callbacks / validation needed before posting and let them prevent things from happening
                if(_self.beforePostCallbacks !== undefined){
                    _self.beforePostCallbacks.forEach(function(callback){
                                        var response = callback();
                                        if(response === false){
                                            valid = false;
                                        }
                                    });
                }

                if(valid){
                    //wipe field right away so no double submission
                    $bodyElem.val('');
                    // Store the submited value to reload it on page load
                    sessionStorage.setItem('comment-body-' + id, body);
                    sessionStorage.setItem('comment-created-' + id, date);

                    $.ajax({
                        url: MegaProxy.baseUrl + '/comments',
                        data: params,
                        type: "POST",
                        xhrFields: { withCredentials: true }
                    });
                    
                    var values = {
                        'body': body,
                        'user': {
                            'username': MegaProxy.users.user.username,
                            'avatar' : MegaProxy.users.user.avatar
                        },
                    };

                    var newTemplate = $('#newTemplate');

                    if(newTemplate.length > 0){
                        $(_self.postOptions.results).prepend($('#newTemplate').tmpl(values));
                    }

                    $container.find('#comment-message').fadeIn().html('Your message is being reviewed').delay(10000).fadeOut();

                    if(_self.afterPostCallbacks !== undefined){
                        _self.afterPostCallbacks.forEach(function(callback){
                            callback();
                        });
                    }
                }

                e.preventDefault();
            });
        };

        this.commentPagination = function($container){
            $container.megaAjaxPagination({
                callback: MegaProxy.baseUrl + '/comments'
            });
        };

        this.paginationMore=function($container){
            $container.megaAjaxPaginationMore({
                callback: MegaProxy.baseUrl + '/comments'
            });
        };
    }

    MegaProxy.extend('comments', new Comments());
})(jQuery);