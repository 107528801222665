/**
 * @todo store all haves wants in one storage bin instead of
 *  one bin per product
 *
 * @todo Remove all storage checks, we should force storage
 */
(function($) {

    function Projects() 
    {
        var _self = this;
        this.countBaseClass = 'proxy-project-count';
        this.countSelector = '.'+this.countBaseClass;
        this.projectSettings = null;
        this.deleteButton = null;
        /**
         * @todo
         *  Be sure to handle the dependency on megaAjaxPagination
         */
        this.init = function()
        {
            if(MegaProxy.users.isLoggedIn) {
                this.logged();
            }
        };

        this.initPostCount = function($elem, options){
            if(!$elem.hasClass(this.countBaseClass)){
                $elem.addClass(this.countBaseClass);
            } 
            this.init();
        };

        this.initPostUserOptions = function($elem, options){
            //commented code is just to document possibilities
            var defaults = {
                //beforeDeleteConfirm:null,  //callback before binding confirm button. 
                deleteButton:".proxy-delete-post",
                deleteConfirm:'#delete-modal-delete',
                container:'.proxy-post-options', //hide show will be based on container if passed
                //afterDeleteConfirm:null //callback after user has confirmed delete;
            };

            this.projectSettings = $.extend(defaults, this.projectSettings, options);
            this.deleteButton = $(this.projectSettings.deleteButton);
            this.init();
        };

        this.update = function(){

        
        };

        this.notLogged = function()
        {
            $(this.countSelector).removeClass('active proxy-initialized');

            if(this.projectSettings !== null){
                $(_self.projectSettings.container).removeClass('proxy-post-options-enabled');

            }

            if(this.deleteButton !== null){
                $(_self.deleteButton).removeClass('proxy-delete-enabled'); 
            }
        };

        /**
         * @todo
         *   Using the id comments is rather confusing
         */
        this.logged = function()
        {
            if(this.countSelector !== null ){
                var $counter = $(_self.countSelector);
                if($counter.length > 0 && !$counter.hasClass('proxy-initialized')){
                    //prevents being called multiple times
                    $counter.addClass('proxy-initialized');
                    $.ajax({
                        url: MegaProxy.baseUrl + '/posted',
                        type: "GET",
                        success: function(response) 
                        {
                            var $counter = $(_self.countSelector);
                            $counter.html(response.count).addClass('active');
                        },
                        error: function() {
                            //TODO: 
                        },
                        xhrFields: { withCredentials: true }
                    });

                }
            }

            if(_self.projectSettings !== null){
                var $container = $(_self.projectSettings.container);

                if($container.length > 0 && $container.data('owner').toLowerCase() === MegaProxy.users.username.toLowerCase() ){
                    $container.addClass('proxy-post-options-enabled');

                    if(this.deleteButton !== null){
                        var $button = $(_self.deleteButton);

                        if($button.length > 0){
                            $button.addClass('proxy-delete-enabled'); 

                            $button.off('click').on('click', function(e){
                                e.preventDefault(); 
                            
                                if(typeof _self.projectSettings.beforeDeleteConfirm == "function"){
                                    _self.projectSettings.beforeDeleteConfirm();
                                }
                                var $confirm = $(_self.projectSettings.deleteConfirm);

                                $confirm.bind('click', function(e){
                                    e.preventDefault();
                                    var id = $button.data("object-id");

                                    $.ajax({
                                        url: MegaProxy.baseUrl + '/projects/'+id,
                                        type: "DELETE",
                                        success: function(response) 
                                        {
                                            if(typeof _self.projectSettings.afterDeleteConfirm == "function"){
                                                _self.projectSettings.afterDeleteConfirm();
                                            }
                                            $button.remove();
                                        },
                                        xhrFields: { withCredentials: true }
                                    });
                                });
                            });
                        }
                    }
                }
            }

        };

    }

    MegaProxy.extend('projects', new Projects());

})(jQuery);