
/**
 * @todo verify the user agent before doing activity calls?
 */
(function($) {

    function Activity() 
    {
        this.store = new sessionStore();
    
        this.send = function(id, type, action, data) 
        { 
            if(MegaProxy.users.isLoggedIn) {
                $.ajax({
                    url: MegaProxy.baseUrl + '/activity',
                    data:{
                        "object_type": type,
                        "object_id": id,
                        "action_type": action,
                        "data": data
                    },
                    type: "POST",
                    success: function(response) 
                    {
                
                    },
                    error: function() {

                    },
                    xhrFields: { withCredentials: true }
                });
            }
        };

        /**
         * Video activity
         */
        var video = function(activity) 
        {
            this.type = 10;
            this.activity = activity;

            this.play = function(id, data) {
                this.activity.send(id, this.type, "play", data);
            };

        }; this.video = new video(this);

        /**
         * Site activity
         */
        var site = function(activity) 
        {
            this.type = 11;
            this.activity = activity;

            //TODO: Once a user gets a badge why would we continue to monitor visits?
            this.visit = function(id, data) {
                if(this.activity.store) {
                    var record = this.activity.store.get(id);
                    if(record) {
                        var count = record+1;
                        this.activity.store.set(id, count);
                    } else {
                        this.activity.send(id, this.type, "visit", data);
                        this.activity.store.set(id, 1);
                    }
                }
            };

            this.link = function(id, data) {
                this.activity.send(id, this.type, "link", data);
            };

        }; this.site = new site(this);

        /**
         * Game activity
         */
        var game = function(activity) 
        {
            this.type = 12;
            this.activity = activity;

            // game play
            this.play = function(id, data) {
                this.activity.send(id, this.type, "play", data);
            };

            // complete levels and collections (collect all parts)
            this.complete = function(id, data) {
                this.activity.send(id, this.type, "complete", data);
            };

        }; this.game = new game(this);

        /**
         * File activity
         */
        var file = function(activity) 
        {
            this.type = 13;
            this.activity = activity;

            //TODO: rubuilds in megabloks.com are merged with instruction
            //  we'll need to indetify rebuilds vs instructions
            this.download = function(id, data) {
                this.activity.send(id, this.type, "download", data);
            };

        }; this.file = new file(this);

    }

    MegaProxy.extend('activity', new Activity());
})(jQuery);