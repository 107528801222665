/**
 * Module for custom events and triggers.
 */
(function($) {

    function Events() 
    {
        var _self = this;
        var watchers = [];
        // TODO: implement this below, 
        //   so that the button class is configurable 
        this.btnSelector = '.report-content';
        this.store = new sessionStore();

        /**
         * Initialize the widget
         */
        this.init = function() 
        {
            _self.triggerEvent('init');
        };

        this.triggerEvent = function (type){

            var event = new CustomEvent("proxyEvent", {
              detail: {
                eventType: type
              },
              bubbles: true,
              cancelable: false
            });

            window.dispatchEvent(event);
        };

        /**
         * Update the data with the users data
         */
        this.update = function() 
        {
            
        };

        /**
         * Display the non logged in state
         */
        this.notLogged = function()
        {
            _self.triggerEvent('logout');
        };

        /**
         * Configure the widget
         */
        this.logged = function() 
        {
            _self.triggerEvent('login');
        };

    }

    MegaProxy.extend('events', new Events());
})(jQuery);