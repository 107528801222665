    /**
     * Module for flagging/reporting bad content.
     */
(function($) {

    function Flags() 
    {
        var _self = this;
        var settings;
        // TODO: implement this below, 
        //   so that the button class is configurable 
        this.baseClass = 'proxy__report--submit';
        this.btnSelector = '.'+this.baseClass;
        this.store = new sessionStore();

        /**
         * Initialize the widget
         */
        this.init = function() 
        {
            if(MegaProxy.users.isLoggedIn) {
                this.logged();
            } else {
               this.notLogged(); 
            } 
        };

        this.jqInit = function($elem){
            if(!$elem.hasClass(_self.baseClass)){
                $elem.addClass(_self.baseClass);
            } 
            this.init();
        };

        /**
         * Update the data with the users data
         */
        this.update = function() 
        {
            
        };

        /**
         * Display the non logged in state
         */
        this.notLogged = function()
        {
            $(this.btnSelector).hide();
        };

        /**
         * Configure the widget
         */
        this.logged = function() 
        {
            this.reportContent();
        };

        this.reportContent = function(options){
            var defaults = {
                    container:".report__container",
                    form:'.proxy-flag-form',
                };

            if(typeof options === 'undefined'){
                options = {};
            }
            
           _self.settings = $.extend(defaults, options);
           var $form = $(_self.settings.form);
           var params = {
                object_id   : $form.find('input[name=object_id]').val(),
                object_type : $form.find('input[name=object_type]').val(),
            };

            var $button = $(this.btnSelector);
            var $container = $(_self.settings.container);


            if(!_self.hasSessionFlag(params)){
                $form.off('submit.proxy-flags').on('submit.proxy-flags', function(e){
                    e.preventDefault();
                    var params = {};

                    //can't serialize objects because js is dumb
                    $.map($form.serializeArray(), function(val, i){
                        params[val.name] = val.value;
                    });

                    $button.prop('disable', true);

                    if(!_self.hasSessionFlag(params)){
                        $form.fadeOut();
                        $container.find('.report__message').addClass('report__message--submitted');
                        $.ajax({
                            url: MegaProxy.baseUrl + '/flags',
                            data: params,
                            type: "POST",
                            success: function(response){
                                var storeId = _self.storeId(params.object_type);
                                var flags = _self.store.get(storeId) || {};
                                var id = params.object_id;

                                flags[id] = response.data.id;
                                _self.store.set(storeId, JSON.stringify(flags));
                            },
                            xhrFields: { withCredentials: true }
                        });
                    }

                    //don't actually submit form
                    return false;
                });

            }
            else{
                $form.fadeOut();
                $container.find('.report__message').addClass('report__message--submitted');
            }
        };

        this.hasSessionFlag = function(params){
            var storeId = _self.storeId(params.object_type);
            var flags = _self.store.get(storeId) || {};
            var id = params.object_id;
            if(flags.hasOwnProperty(id) && flags[id] !== 0){
                return true;
            }
            return false;
        };

        this.storeId = function(object_type) 
        {
           return "flags_" + object_type; 
        };

    }

    MegaProxy.extend('flags', new Flags());
})(jQuery);