(function($) {

    function Users(){
        var _self = this;
        /**
         * Initialize the widget
         */
        this.init = function()
        {
            this.configure();

            if(this.isLoggedIn) {
                this.logged();
            } else {
               this.notLogged();
            }
        };

        this.reinit = function(){
            this.configure();
        };

        this.refreshUserInfo = function(){
            var m = MegaProxy;
            $.ajax({
                url: m.baseUrl + '/me',
                success: function(response) {
                    if(response !=="" && m.hasOwnProperty('users')){
                        //we have user data but maybe we don't have a valid user based on configuration options
                        if(_self.username !== response.data.username && m.loginProcessed === true){
                            //we only need to force another global login event on all proxy modules if the user changed somehow.
                            m.loginProcessed = false;
                        }

                        m.users.configure(response.data);
                        if(m.users.isLoggedIn === true && m.loginProcessed === false){
                            m.login();
                        }
                    }
                    else{
                        m.logout();
                    }
                },
                xhrFields: { withCredentials: true }
            });
        };

        // MegaProxy.baseUrl + '/me' will pass in user data,
        this.configure = function(user){
            var data;
            if(typeof user ==="undefined"){
                user = false;
                //try to load from cookie
                data = MegaProxy.getCookie('pru');
                if(data) {
                   user = JSON.parse(data);
                }
            }

            this.isLoggedIn = false;
            this.username = 'Anonymous';
            this.user = user;


            //disallow kids to appear logged in on certain sites while maintaining their cookie
            if(Number(user.auth)===0 && MegaProxy.settings.allowKids===false){
                return;
            }

            if(user && user.hasOwnProperty('username')) {
                this.isLoggedIn = true;
                this.username = user.username;

                //refresh the avatar / username
                $('.proxy-user-avatar').attr('src', this.user.avatar.url);
                $('.proxy-user-avatar').attr('alt', this.user.username);
                $('.proxy-user-name').html(this.user.username);

                if(user.hasOwnProperty('notifications') && user.notifications == 1){
                    $('.proxy-notify').addClass('proxy-notify--notifications');
                }
                else{
                    $('.proxy-notify').removeClass('proxy-notify--notifications');
                }
            }
        };

        /**
         * Update the data with the users data
         *
         * @todo limit the number of requests this does, notifications can be delayed
         *  we could execute this call every 15, 20 or even 30 minutes
         * Add somethign to session storage to prevent too many calls.
         *  rememebr that session storage does not work across tabs
         */
        this.update = function()
        {
            //console.log(MegaProxy);
        };

        /**
         * Display the non logged in state
         */
        this.notLogged =  function()
        {
            // logged state header class
            $('.proxy-header').removeClass('proxy-is-logged').addClass('proxy-is-not-logged');

            $('.proxy-requires-login').off('click.proxy-login').on('click.proxy-login', function(e){            
                e.preventDefault();

                var $modal = $('.proxy-login-modal');
                if($modal.length > 0){
                    $modal.addClass('active');
                    $modal.on('click', function(e){
                        if(e.target === this){
                            $modal.removeClass('active');
                            $modal.off('click');
                        }
                    }); 
                } 
                else{
                    _self.triggerLogin();  
                }
            });

            $('.proxy-trigger-login').off('click').on('click', this.triggerLogin);
            $('.proxy-trigger-signup').off('click').on('click', this.triggerSignup);
        };

        /**
         * Configure the widget
         */
        this.logged = function()
        {
            //TODO - this might be redundant now, leaving in as a temporary measure.
            $('.proxy-user-avatar').attr('src', this.user.avatar.url);
            $('.proxy-user-name').html(this.user.username);
            $('.proxy-user-link').each(function(){
                var $that = $(this);
                $that.attr('href', $that.data('url').replace('[username]', _self.user.username));
            });
            // logged state header class
            $('.proxy-header').removeClass('proxy-is-not-logged').addClass('proxy-is-logged');
            $('.proxy-requires-login').off('click.proxy-login');
            $('.proxy-trigger-login').off('click');
            $('.proxy-trigger-signup').off('click');
        };

        this.jqAuth = function(authLevel){
            if(this.user.hasOwnProperty('auth')){
                return authLevel == this.user.auth;
            }
            return false;
        };

        this.ageRestrictionHandler = function($elem, options){

            if(this.user.hasOwnProperty('auth')){

                switch(this.user.auth){
                    case 2: //over 18
                        $elem.find(options.over18).show();
                        $elem.find(options.over13).hide();
                        $elem.find(options.under13).hide();
                        break;
                    case 1: //over 13 under 18
                        $elem.find(options.over18).hide();
                        $elem.find(options.over13).show();
                        $elem.find(options.under13).hide();
                        break;
                    default: //under 13
                        $elem.find(options.over18).hide();
                        $elem.find(options.over13).hide();
                }
            }
        };

        this.triggerLogin = function(e){
            if(typeof e !== "undefined"){
                e.preventDefault();
            }
            var url = $('.proxy-login-link').attr('href');
            window.location.href = url;
        }

        this.triggerSignup = function(e){
            if(typeof e !== "undefined"){
                e.preventDefault();
            }
            var url = $('.proxy-signup-link').attr('href');
            window.location.href = url;
        }
    }

    MegaProxy.extend('users', new Users());

})(jQuery);
