    /**
     * @todo store all haves wants in one storage bin instead of
     *  one bin per product
     *
     * @todo Remove all storage checks, we should force storage
     */
(function($) {

    function Followings() 
    {
        var _self = this;
        // TODO: implement this below, 
        //   so that the button class is configurable 
        this.baseClass = 'proxy-follow';
        this.btnSelector = '.'+ this.baseClass;
        this.store = new sessionStore();

        /**
         * Initialize the widget
         */
        this.init = function() 
        { 
            if(!_self.isInitialized()){
                if(MegaProxy.users.isLoggedIn) {
                    _self.logged.call(this);
                } else {
                   _self.notLogged.call(this);
                } 

                $(_self.btnSelector).addClass('proxy-initialized');
            }
        };

        this.isInitialized = function(){
            return $(_self.btnSelector).hasClass('proxy-initialized');
        };

        this.jqInit = function($elem, options){
            if(!$elem.hasClass(_self.baseClass)){
                $elem.addClass(_self.baseClass);
            } 

            if(typeof options === 'undefined'){
                options = {};
            }

            if(options.hasOwnProperty("onFollow")){
                _self.pushCallback('onFollow', options.onFollow);
            }

            if(options.hasOwnProperty("offFollow")){
                _self.pushCallback('offFollow', options.offFollow);
            }
            if(options.hasOwnProperty("onComplete")){
                _self.pushCallback('onComplete', options.onComplete);
            }

            _self.init();
        };

        this.pushCallback= function(type, callback){
            switch(type){
                case 'onFollow':
                    var onFollowCallbacks = _self.onFollowCallbacks || [];
                    onFollowCallbacks.push(callback);
                    _self.onFollowCallbacks = onFollowCallbacks;
                    break;
                case 'offFollow':
                    var offFollowCallbacks = _self.offFollowCallbacks || [];
                    offFollowCallbacks.push(callback);
                    _self.offFollowCallbacks = offFollowCallbacks;
                    break;
                case 'onComplete':
                    var onCompleteCallbacks = _self.onCompleteCallbacks || [];
                    onCompleteCallbacks.push(callback);
                    _self.onCompleteCallbacks = onCompleteCallbacks;
                    break;
            }
        }

        this.executeCallbacks = function(type, context){

        var callbacks;
            switch(type){
                case 'onFollow':
                    callbacks = _self.onFollowCallbacks;
                    break;
                case 'offFollow':
                    callbacks = _self.offFollowCallbacks;
                    break;
                case 'onComplete':
                    callbacks = _self.onCompleteCallbacks;
                    break;
            }

            if(callbacks !== undefined){
                callbacks.forEach(function(callback){
                    callback.call(context);
                });
            }
        }

        /**
         * Update the data with the users data
         */
        this.update = function() 
        {
            if(!MegaProxy.users.isLoggedIn){
                return;
            }
            var store = _self.store,
            $button = $(_self.btnSelector),
            params = $button.data('params'),
            count = parseInt($('.'+$button.data('output')).html());

            if(params){

                var storeId = _self.storeId(params.object_type),
                followings = _self.store.get(storeId),
                id = params.object_id;
                if(!followings) {
                    followings = {};
                }

                //even if we have the session we might not have the necessary action id to unfollow. Try to get.
                if(!followings.hasOwnProperty(id) || !followings[id].hasOwnProperty('action_id')) {
                    jQuery.ajax({
                        url: MegaProxy.baseUrl + '/followed',
                        data: params,
                        type: "GET",
                        success: function(response) 
                        {
                            if(response.hasOwnProperty('data') && response.data.followings !== null){
                                params.action_id = response.data.followings;
                                $button.data('params', params).addClass('proxy-followed').find('.item-label').html($button.data('label-followed'));
                                followings[id] = { "followings": count, 'action_id': response.data.followings };
                                _self.executeCallbacks('onFollow', $button[0]);
                            }

                            //Store the new haves/wants count
                            store.set(storeId, JSON.stringify(followings));
                        },
                        xhrFields: { withCredentials: true }
                    });
                }

                if(_self.hasSessionFollow($button)){
                    count++;
                    if(followings[id].hasOwnProperty('action_id')){
                        params.action_id = followings[id].action_id;
                    }
                    $button.data('params', params).addClass('proxy-followed').find('.item-label').html($button.data('label-followed'));
                    _self.executeCallbacks('onFollow', $button[0]);
                    $('.'+ $button.data('output')).html(count);
                }
            }
        };

        /**
         * Display the non logged in state
         */
        this.notLogged = function()
        {
            $button = $(_self.btnSelector);
            $button.off('click.proxy-follow').removeClass('proxy-followed').find('.item-label').html($button.data('label-follow'));
        };

        /**
         * Configure the widget
         */
        this.logged = function() 
        {
            _self.update();

            var store = _self.store;
            $(_self.btnSelector).off('click.proxy-follow').on('click.proxy-follow',function(e){
                e.preventDefault();
                var $button = $(this);

                // Decrement displayed value
                if($button.hasClass('proxy-followed')) {
                    //todo this should be passed in
                    var output = jQuery('.'+$button.data('output'));
                    if (parseInt(output.html(), 10) > 0) {
                        output.html(parseInt(output.html(), 10) - 1);
                    }
                }

                var endpoint = 'followings'; //line below leaks global var (endpoint)
                var outputId = $button.data('output');
                var params = $button.data('params');

                var storeId = MegaProxy.followings.storeId(params.object_type);

                var followings = store.get(storeId);
                if(!followings) {
                    followings = {};
                }

                // Clicking an inactive action button
                if(!$button.hasClass('proxy-followed')) {

                    var id = params.object_id;
                    var output = $('.'+outputId);
                    var count = parseInt(output.html(), 10) + 1;

                    output.html(count);

                    followings[id] = {}; 
                    followings[id][outputId] = count;

                    store.set(storeId, JSON.stringify(followings));
                    $button.addClass('proxy-followed').find('.item-label').html($button.data('label-followed'));

                    // Add user action
                    jQuery.ajax({
                        url: MegaProxy.baseUrl + '/followings',
                        data: params,
                        type: "POST",
                        success: function(response) {
                            if (response.code == 201) {
                                params.action_id = response.data.id;
                                $button.data('params', params);
                                //store the action id on the session once we have it
                                followings[id].action_id = response.data.id;
                                store.set(storeId, JSON.stringify(followings));

                                _self.executeCallbacks('onFollow', $button[0]);
                                _self.executeCallbacks('onComplete', $button[0]);
                            }
                        },
                        error: function(error) {
                        },
                        xhrFields: { withCredentials: true }
                    });

                // Clicking an active action button
                } else {

                    // Reset all action buttons
                    $button.removeClass('proxy-followed').find('.item-label').html($button.data('label-follow'));

                    var id=params.object_id;
                    delete followings[id];

                    store.set(storeId, JSON.stringify(followings));

                    if(typeof params.action_id != "undefined"){
                        // Delete user action
                        jQuery.ajax({
                            url: MegaProxy.baseUrl + '/followings/' + params.action_id,
                            type: "DELETE",
                            success: function(response) {
                                if (response.code == 204) {
                                    delete params.action_id;
                                    $button.data('params', params);
                                    _self.executeCallbacks('offFollow', $button[0]);
                                    _self.executeCallbacks('onComplete', $button[0]);
                                }
                            },
                            error: function(error) {
                            },
                            xhrFields: { withCredentials: true }
                        });
                    }
                    
                }

            });


        };

        this.updateCount = function(options){

            var $button = $(_self.btnSelector),
                params = $button.data('params');

            if($button.length > 0){
               $.ajax({
                   url: MegaProxy.baseUrl + '/followings',
                   data: params,
                   type: "GET",
                   success:function(response){
                       if(response.hasOwnProperty('data')){
                            //Race condition with session variable - check if session exists and increment
                            var count = response.data.count;
                            if(_self.hasSessionFollow($button)) {
                                count++;
                            }
                            $button.find('.'+$button.data('output')).html(count);
                       }
                   },
                   xhrFields: { withCredentials: true }
               });
            }
        };

        this.hasSessionFollow = function($button){
            var params = $button.data('params');
            var storeId = _self.storeId(params.object_type);
            var followings = _self.store.get(storeId) || {};
            var id = params.object_id;
            if(followings.hasOwnProperty(id) && followings[id] !== 0){
                return true;
            }
            return false;
        };

        this.storeId = function(object_type) 
        {
           return "followings_" + object_type; 
        };
    }

    MegaProxy.extend('followings', new Followings());
})(jQuery);