    /**
     * @todo store all haves wants in one storage bin instead of
     *  one bin per product
     *
     * @todo Remove all storage checks, we should force storage
     */
(function($) {

    function Reviews()
    {
        var _self = this;
        this.review_btn = $('#review-button');
        this.container=$('#review-form-container');
        this.formOptions = {};
        this.postOptions = {};
        /**
         * @todo
         *  Be sure to handle the dependency on megaAjaxPagination
         */
        this.init = function()
        {
            //likely this will be removed
            this.pagination($('#customer-reviews'));
            if(MegaProxy.users.isLoggedIn) {
                this.logged();
            }
        };

        this.pagination=function($container){
            $container.megaAjaxPagination({
                callback: MegaProxy.baseUrl + '/reviews'
            });
        };

        this.paginationMore=function($container){
            $container.megaAjaxPaginationMore({
                callback: MegaProxy.baseUrl + '/reviews'
            });
        };

        this.notLogged = function()
        {
            this.container.hide();
        };

        this.refreshReviews = function($container, options){
            var defaults = {
                template:"#resultTemplate",
                pagerTemplate:"#paginationTemplate",
                result:".result-ajax",
                paginationWrapper:".pagination-ajax",
                count:'.reviews-count'
            };

            if(typeof options === 'undefined'){
                options = {};
            }
            
           var settings = $.extend(defaults, options);

           if($container.length > 0){
                var params = {
                    object_id   : $container.data('objectid'),
                    object_type : $container.data('objecttype'),
                    limit       : $container.data('limit'), 
                    page        : 1
                };

                $.ajax({
                    url: MegaProxy.baseUrl + '/reviews',
                    data: params,
                    type: "GET", 
                    success:function(response){
                        if(response.hasOwnProperty('data') && response.data.results !== null){
                            $(settings.count).html(response.data.pager.count);
                            var $result_container = $(settings.result),
                            $ajax_container = $(settings.paginationWrapper);
                            $result_container.empty();
                            $(settings.template).tmpl(response.data.results).appendTo($result_container);

                            if($ajax_container.length > 0){
                                if(response.data.pager.pages > 1){
                                    $ajax_container.empty();
                                    $(settings.pagerTemplate).tmpl(response.data.pager).appendTo($ajax_container);
                                    $container.reviews().pagination();
                                }
                            }

                            _self.userReviewContainer($container);
                        }
                    },
                    xhrFields: { withCredentials: true }
                });
           }
        };

        this.initReviewForm = function($container, options){
            this.container = $container;

            var defaults = {
                activeClass:'jsStar',
                inactiveClass:'',
                reviewContainer:'#customer-reviews'
            };

            if(typeof options === 'undefined'){
                options = {};
            }

            _self.formOptions = $.extend(defaults, _self.formOptions, options);

            //don't push callbacks more than once. Use only what's passed in
            if(options.hasOwnProperty("onLogout")){
                MegaProxy.registerCallback('logout', options.onLogout);
            }

            if ($container.length > 0) {
                $container.show();
                //always showing now
                _self.userReviewContainer($(_self.formOptions.reviewContainer));
            }

            //TODO - Remove this once older micro sites stop using the js stars.
            var $stars = $container.find('li');
            $container.find('li').each(function(index, elem)
            {
                var $that = $(this),
                ul = $that.parents('ul');

                $that.mouseenter({index : index, ul : ul}, function(event){
                    var star = event.data.index + 1;
                    $stars.each(function(index, elem){

                        if(index < star) {
                            $(this).addClass(_self.formOptions.activeClass).removeClass(_self.formOptions.inactiveClass);
                        } else {
                            $(this).removeClass(_self.formOptions.activeClass).addClass(_self.formOptions.inactiveClass);
                        }
                    });
                })
                .mouseout(function(event){
                    $stars.each(function(index, elem){
                        if($(this).hasClass('reviewed') === false) {
                            $(this).removeClass(_self.formOptions.activeClass).removeClass(_self.formOptions.inactiveClass);
                        } else {
                            $(this).addClass(_self.formOptions.activeClass).addClass(_self.formOptions.inactiveClass);
                        }
                    });
                })
                .click({index : index, starField : $container.find('input#review-rating'), ul : ul}, function(event){
                    var star = event.data.index + 1;
                    event.data.starField.val(star);

                    $stars.each(function(index, elem){
                        if(index < star) {
                            $(this).addClass(_self.formOptions.activeClass + ' reviewed').removeClass(_self.formOptions.inactiveClass);
                        } else {
                            $(this).removeClass(_self.formOptions.activeClass + ' reviewed').addClass(_self.formOptions.inactiveClass);
                        }
                    });
                });
            });
        };

        /**
         * @todo
         *   Using the id comments is rather confusing
         */
        this.logged = function()
        {
            $('#review-login').hide();

            //set up defaults button
            this.postReviewEvent(this.review_btn);
            this.initReviewForm(this.container);
        };

        this.postReviewEvent = function($button, settings){
            this.review_btn = $button;
            var defaults = {
                    container:'#review-form-container',
                    form:'#review-form',
                    rating:'#review-rating',
                    results:'.result-ajax',
                    template:'#newTemplate'
                };

            if(typeof settings === 'undefined'){
                settings = {};
            }
            
            _self.postOptions = $.extend(defaults, _self.postOptions, settings);

            //Use passed in settings only to not push same events over and over. 
            if(settings.hasOwnProperty("beforePost")){
                this.pushBeforePostCallbacks(settings.beforePost);
            }
            if(settings.hasOwnProperty("afterPost")){
                this.pushAfterPostCallbacks(settings.afterPost);
            }

            //This should only happen when logged in
            if(MegaProxy.users.isLoggedIn){
                //unbind event in case prior event set
                $button.off('click.proxy-review-button').on('click.proxy-review-button', function(e)
                {
                    e.preventDefault();
                    var $container = $(_self.postOptions.container),
                    id = $container.find("input[name='object_id']").val(),
                    params = $container.find(_self.postOptions.form).serialize(),
                    rating = $container.find(_self.postOptions.rating).val();

                    var valid = true;
                    if(rating == 0) {
                        jQuery('#review-message-rating').slideDown();
                        valid = false;
                    } else {
                        jQuery('#review-message-rating').slideUp();
                    }

                    var body = jQuery('textarea[name=body]').val();
                    if(body.trim().length == 0) {
                        jQuery('#review-message-review').slideDown();
                        valid = false;
                    } else {
                        jQuery('#review-message-review').slideUp();
                    }

                    //allow this callback to return false and stop the post from submitting
                    if(_self.beforePostCallbacks !== undefined){
                        _self.beforePostCallbacks.forEach(function(callback){
                                            var response = callback();
                                            if(response === false){
                                                valid = false;
                                            }
                                        });
                    }

                    if(valid){
                        $button.off('click.proxy-review-button');
                        // Store the submited values to reload them on page load
                        sessionStorage.setItem('review-body-' + id, body);
                        sessionStorage.setItem('review-rating-' + id, rating);

                        var values = {
                            'body': body,
                            'rating': rating,
                            'user': {
                                'username': MegaProxy.users.user.username,
                                'avatar' : MegaProxy.users.user.avatar
                            },
                        };
                        $(_self.postOptions.results).prepend($('#newTemplate').tmpl(values));
                        $('.proxy-cold-start').hide();
                        
                        $container.slideUp();
                        jQuery.ajax({
                            url: MegaProxy.baseUrl + '/reviews',
                            data: params,
                            type: "POST",
                            xhrFields: { withCredentials: true }
                        });

                        if(_self.afterPostCallbacks !== undefined){
                            _self.afterPostCallbacks.forEach(function(callback){
                                callback();
                            });
                        }
                    }
                });
            }
        };

        this.userReviewContainer = function($container){
            var page = parseInt($('.pager-active a').html()) || 0;
            if($container.find('.result-ajax').length > 0 && page <= 1){
                var id = $container.data("objectid");
                if (!sessionStorage.getItem('review-body-' + id)) {
                    // Display the comment div
                    $('#comment-form-container').show();
                } else if(MegaProxy.users.isLoggedIn){
                    var values = {
                        'body': sessionStorage.getItem('review-body-' + id),
                        'rating': sessionStorage.getItem('review-rating-' + id),
                        'user': {
                            'username': MegaProxy.users.user.username,
                            'avatar' : MegaProxy.users.user.avatar
                        },
                    };
                    jQuery('.result-ajax').prepend($('#newTemplate').tmpl(values));
                    $('.proxy-cold-start').hide();
                }
            }
        };

        this.pushBeforePostCallbacks = function(callback){
            var beforePostCallbacks = this.beforePostCallbacks || [];
            beforePostCallbacks.push(callback);
            this.beforePostCallbacks = beforePostCallbacks;
        };

        this.pushAfterPostCallbacks = function(callback){
            var afterPostCallbacks = this.afterPostCallbacks || [];
            afterPostCallbacks.push(callback);
            this.afterPostCallbacks = afterPostCallbacks;
        };
    }

    MegaProxy.extend('reviews', new Reviews());

})(jQuery);
