(function($) {

    $.megaproxy = {
        onLogin:function(callback){
            if(MegaProxy.isModuleEnabled('users') && MegaProxy !== 'undefined'){
                MegaProxy.registerCallback('login', callback);

                //run immediately if megaproxy user is already initialized
                if(MegaProxy.users.isLoggedIn){
                    callback(MegaProxy.users.user);
                }
            }
        }, 
        onLogout:function(callback){
            if(MegaProxy.isModuleEnabled('users') && MegaProxy !== 'undefined'){
                MegaProxy.registerCallback('logout', callback);

                //run immediately if megaproxy has no user
                if(!MegaProxy.users.isLoggedIn){
                    callback();
                }
            }
        },
        onReInit:function(callback){
            if(MegaProxy.isModuleEnabled('users') && MegaProxy !== 'undefined'){
                MegaProxy.registerCallback('reinit', callback);
            }
        }
    };


    $.fn.comments = function(){
        var $elem = this,
        module = 'comments';

        this.postComment = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.comments.postCommentEvent($elem, options);
            }
        
            return $elem;
        };

        this.pagination = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.comments.commentPagination($elem); 
            }

            return $elem;
        };

        this.paginationMore = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.comments.paginationMore($elem); 
            }

            return $elem;
        };

        this.refresh = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.comments.refreshComments($elem, options); 
            }

            return $elem;
        };

        return this;
    };


    $.fn.reviews = function(){
        var $elem = this,
        module = 'reviews';

        this.reviewForm = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.reviews.initReviewForm($elem, options);
            }

            return $elem;
        };

        this.postReview = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.reviews.postReviewEvent($elem, options);
            }

            return $elem;
        };

        this.pagination = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.reviews.pagination($elem); 
            }

            return $elem;
        };

        this.paginationMore = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.reviews.paginationMore($elem); 
            }

            return $elem;
        };

        this.refresh = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.reviews.refreshReviews($elem, options); 
            }

            return $elem;
        };

        return this;
    };

    $.fn.haves_wants = function(){
        var $elem = this, 
        module = 'haves_wants';

        if(MegaProxy.isModuleEnabled(module)){
            MegaProxy.haves_wants.jqInit($elem); 
        }

        this.refresh = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.haves_wants.updateCounts($elem, options); 
            }
        };

        return this;

    };

    $.fn.likes = function(options){
        var $elem = this, 
        module = 'likes';

        if(MegaProxy.isModuleEnabled(module)){
            MegaProxy.likes.jqInit($elem, options); 
        }

        this.refresh = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.likes.updateCount($elem, options); 
            }
        };

        return this;
    };

    $.fn.followings = function(options){
        var $elem = this, 
        module = 'followings';

        if(MegaProxy.isModuleEnabled(module)){
            MegaProxy.followings.jqInit($elem, options); 
        }

        this.refresh = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.followings.updateCount($elem, options); 
            }
        };

        return this;
    };

    $.fn.projects = function(){
        var $elem = this, 
        module = 'projects';

        this.count = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.projects.initPostCount($elem, options);
            }
        };

        this.postOptions = function(options){
            if(MegaProxy.isModuleEnabled(module)){
                MegaProxy.projects.initPostUserOptions($elem, options);
            }
        };

        return this;
    };

    $.fn.social = function(){
        var $elem = this, 
        module = 'social';
        if(MegaProxy.isModuleEnabled(module)){
            var options = $.extend({
                onLoad:function(){},
            }, arguments[0] || {}); 

            MegaProxy.social.jqInit($elem, options); 
        }
    };

    $.fn.users = function(){
        var $elem = this, 
        module = 'users';

        this.under18 = function(callback){
            this.isNotAuth(2, callback);
            return this;
        };

        this.over18 = function(callback){
            this.auth(2, callback);
            return this;
        };

        this.over13 = function(callback){
            this.auth(1, callback);
            return this;
        };

        this.under13 = function(callback){
            this.auth(0, callback);

            return this;
        };

        this.auth = function(authLevel, callback){
            if($elem.length !== 0 && MegaProxy.isModuleEnabled(module)){
                if(MegaProxy.users.jqAuth(authLevel)){
                    callback.call($elem);
                }
            }

            return this;
        };

        this.isNotAuth = function(authLevel, callback){
            if($elem.length !== 0 && MegaProxy.isModuleEnabled(module)){
                if(!MegaProxy.users.jqAuth(authLevel)){
                    callback.call($elem);
                }
            }

            return this;
        };

        return this;
    };

})(jQuery);

