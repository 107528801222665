(function ($, window, undefined) {
    
    var MegaAjaxPaginationMore = MegaAjaxPaginationMore || {};
    
    $.fn.megaAjaxPaginationMore = function(options) {
        var settings = $.extend({
            paginationLink      : 'proxy-paginate-more',
            paginationTemplate  : 'paginationTemplate',
            resultClass         : 'result-ajax',
            resultTemplate      : 'resultTemplate',
            callback            : '/mega/ajax/pagination'
        }, options );
        
        MegaAjaxPaginationMore.Bind(this, settings);
    };
    
    MegaAjaxPaginationMore.cache = [];
    MegaAjaxPaginationMore.clicked = [];
    
    MegaAjaxPaginationMore.Bind = function($container, settings) {
        var $paginationLink = $container.find('.' + settings.paginationLink);
        var $ajax_result = $container.find('.' + settings.resultClass);
        debugger;
        $paginationLink.click(function(e) {
            var page = $(this).data('page') || 1;
            if(MegaAjaxPaginationMore.clicked[page] === undefined) {
                $.ajax({
                    type: 'GET',
                    url: settings.callback,
                    cache: true,
                    dataType: 'json',
                    data: { 
                        object_id   : $container.data('objectid'),
                        object_type : $container.data('objecttype'),
                        limit       : $container.data('limit'),
                        page        : page
                    },
                    success: function(response) {
                        MegaAjaxPaginationMore.cache[page] = response.data;
                        MegaAjaxPaginationMore.UpdateHtml($container, settings, $paginationLink, $ajax_result, response.data);
                    },
                    xhrFields: { withCredentials: true }
                });
            }
            else if(MegaAjaxPaginationMore.cache[page] !== undefined) {
                MegaAjaxPaginationMore.UpdateHtml($container, settings, $paginationLink, $ajax_result, MegaAjaxPaginationMore.cache[page]);
            }
            MegaAjaxPaginationMore.clicked[page] = true;
            e.preventDefault();
        });
    };
    
    MegaAjaxPaginationMore.UpdateHtml = function($container, settings, $paginationLink, $ajax_result, data) {
        //$ajax_result.empty();
        $('#' + settings.resultTemplate).tmpl(data.results).appendTo($ajax_result);


        if( data.pager.nextPage <= data.pager.pages){
            $paginationLink.data('page', data.pager.nextPage);
        }
        else{
            $paginationLink.hide();    
        }
        

        MegaAjaxPaginationMore.Bind($container, settings);
    };
    
    $.extend(jQuery.tmpl.tag, {
        "for": {
            _default: {$2: "var i=1;i<=1;i++"},
            open: 'for ($2){',
            close: '};'
        }
    });
}(jQuery, window));