function sessionStore()
    {
        this.sessStore = false;
        
        // Check for native support
        if (sessionStorage) {
            this.sessStore = true;
        }

        this.set = function(key, value) 
        {
            // Convert object values to JSON
            if ( typeof value === 'object' ) {
                value = JSON.stringify(value);
            }
            // Set the store
            if (this.sessStore) { // Native support
                sessionStorage.setItem(key, value);
            } else { // Use Cookie
                this.createCookie(key, value, 30);
            }
        };

        this.get = function(key)
        {       
            // Get value
            if (this.sessStore) { // Native support
                data = sessionStorage.getItem(key);
            } else { // Use cookie 
                data = this.readCookie(key);
            }
            
            // Try to parse JSON...
            try {
               data = JSON.parse(data);
            }
            catch(e) {
               data = data;
            }
            
            return data;
        };

        this.remove = function(key)
        {
            if (this.sessStore) { // Native support
                sessionStorage.removeItem(key);
            } else { // Use cookie
                this.createCookie(key, '', -1);
            }
        };

        this.createCookie = function(key, value, exp) {
            var date = new Date();
            date.setTime(date.getTime() + (exp * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
            document.cookie = key + "=" + value + expires + "; path=/";
        };

        this.readCookie = function(key) {
            var nameEQ = key + "=";
            var ca = document.cookie.split(';');
            for (var i = 0, max = ca.length; i < max; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        };
    }
function localStore()
    {

        this.locStore = false;
        
        // Check for native support
        if (localStorage) {
            this.locStore = true;
        }

        this.set = function(key, value) 
        {
            // Convert object values to JSON
            if ( typeof value === 'object' ) {
                value = JSON.stringify(value);
            }
            // Set the store
            if (this.locStore) { // Native support
                localStorage.setItem(key, value);
            } else { // Use Cookie
                this.createCookie(key, value, 30);
            }
        };

        this.get = function(key)
        {       
            // Get value
            if (this.locStore) { // Native support
                data = localStorage.getItem(key);
            } else { // Use cookie 
                data = this.readCookie(key);
            }
            
            // Try to parse JSON...
            try {
               data = JSON.parse(data);
            }
            catch(e) {
               data = data;
            }
            
            return data;
        };

        this.remove = function(key)
        {
            if (this.locStore) { // Native support
                localStorage.removeItem(key);
            } else { // Use cookie
                this.createCookie(key, '', -1);
            }
        };

        this.createCookie = function(key, value, exp) {
            var date = new Date();
            date.setTime(date.getTime() + (exp * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
            document.cookie = key + "=" + value + expires + "; path=/";
        };

        this.readCookie = function(key) {
            var nameEQ = key + "=";
            var ca = document.cookie.split(';');
            for (var i = 0, max = ca.length; i < max; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        };
    }

